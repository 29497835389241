import styled from 'styled-components';
import { css } from '@styled-system/css';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const linkProps = {
	color: 'inherit',
	textDecoration: 'none',
};

export const StyledInlineWrapper = styled.span(({ color }) =>
	css({
		width: 'fit-content',
		color: color || 'inherit',
	})
);

export const StyledGatsbyLink = styled(AnchorLink)(({ withUnderline }) =>
	css({
		...linkProps,

		'&:hover': {
			textDecoration: withUnderline ? 'underline' : 'none',
		},
	})
);
