import { merge, get } from 'lodash';
import { colors } from './colors';
import { space, fontSizes, fontWeights, breakpoints } from './config';

export const theme = {
	space,
	colors,
	fontSizes,
	fontWeights,
	breakpoints,
};

export const getTheme = (mode) =>
	merge({}, theme, {
		colors: get(theme.colors.modes, mode),
	});
