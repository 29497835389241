import PropTypes from 'prop-types';
import { css } from '@styled-system/css';
import styledSystemPropTypes from '@styled-system/prop-types';
import styled from 'styled-components';
import {
	space,
	typography,
	variant,
	layout,
	flexbox,
	compose,
} from 'styled-system';
import { fontWeights } from 'theme/config';
import { variants } from './paragraphVariants';

export const Paragraph = styled.p(
	({
		color,
		display,
		maxWidth,
		minWidth,
		wordBreak,
		textAlign,
		fontWeight,
		alignItems,
		whiteSpace,
		justifyContent,
	}) =>
		css({
			margin: 0,
			overflow: 'hidden',
			color: color || 'black',
			display: display || 'flex',
			maxWidth: maxWidth || 'unset',
			textAlign: textAlign || 'left',
			whiteSpace: whiteSpace || 'unset',
			fontWeight: fontWeight || 'normal',
			alignItems: alignItems || 'center',
			minWidth: minWidth || 'fit-content',
			wordBreak: wordBreak || 'break-word',
			justifyContent: justifyContent || 'unset',
		}),
	variant({
		variants,
	}),
	compose(space, typography, layout, flexbox)
);

Paragraph.propTypes = {
	...styledSystemPropTypes.space,
	...styledSystemPropTypes.layout,
	...styledSystemPropTypes.flexbox,
	...styledSystemPropTypes.typography,
	variant: PropTypes.oneOf([...Object.keys(variants)]),
	fontWeight: PropTypes.oneOf([...Object.keys(fontWeights)]),
};

Paragraph.defaultProps = {
	variant: 'body',
	fontWeight: 'normal',
};
