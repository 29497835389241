import { Drawer } from 'antd';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { FlexBox } from 'components/common/FlexBox';

export const StyledDrawer = styled(Drawer)(
	css({
		'.ant-drawer-header, .ant-drawer-body': {
			px: 'md',
			pb: 'sm',
			pt: 'xxxl',
			bg: 'document_bg',
			justifyContent: 'center',
		},

		'.ant-drawer-header': {
			border: 'none',
		},

		'.ant-drawer-title': {
			width: '100%',
			display: 'flex',
			position: 'relative',
			justifyContent: 'center',
		},

		'.ant-drawer-header-title': {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},

		'.ant-drawer-body': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},

		'.ant-drawer-close': {
			zIndex: 1,
			margin: 0,
			padding: 0,
			right: '24px',
			display: 'flex',
			alignItems: 'center',
			position: 'absolute',

			svg: {
				color: 'primary',
			},
		},
	})
);

export const MenuWrapper = styled(FlexBox)(
	css({
		mb: 'xxxl',
		width: '100%',
		pt: [0, 'sm', 'md'],
		flexDirection: 'column',
		gap: ['xxl', 'xxl', 'xxxl'],
	})
);
