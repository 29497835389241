import PropTypes from 'prop-types';
import { ChildrenType } from 'components/common/Types';
import { Paragraph } from 'components/common/Typography';
import { StyledAnchor, StyledInlineWrapper } from './styles';

/**
 * A custom hyperlink component for an external routing.
 *
 * @param {Object} AnchorProps - The properties of the Anchor component.
 * @param {string} AnchorProps.href - The URL that the link should navigate to.
 * @param {string} AnchorProps.ariaLabel - The link description for screen readers if label not present.
 * @param {boolean} AnchorProps.inline - Determines whether the link should be rendered inline or as a paragraph.
 * @param {JSX.Element} AnchorProps.children - The content to be rendered within the link.
 * @param {Object} AnchorProps.textProps - Additional text properties that should be applied to the link.
 * @param {boolean} AnchorProps.openInNewTab - Determines whether the link should open in a new tab.
 * @param {boolean} AnchorProps.withUnderline - Determines whether the link should have an underline when being hovered.
 * @returns {JSX.Element} - The rendered Anchor component.
 */
export const Anchor = ({
	href,
	inline,
	children,
	textProps,
	ariaLabel,
	openInNewTab,
	withUnderline,
}) => {
	const baseComponent = (
		<StyledAnchor
			href={href}
			aria-label={ariaLabel}
			withUnderline={withUnderline}
			target={openInNewTab ? '_blank' : undefined}
			rel={openInNewTab ? 'noreferrer' : undefined}
		>
			{children}
		</StyledAnchor>
	);

	return inline ? (
		<StyledInlineWrapper {...textProps}>
			{baseComponent}
		</StyledInlineWrapper>
	) : (
		<Paragraph {...textProps}>{baseComponent}</Paragraph>
	);
};

Anchor.propTypes = {
	href: PropTypes.string,
	inline: PropTypes.bool,
	children: ChildrenType,
	ariaLabel: PropTypes.string,
	openInNewTab: PropTypes.bool,
	withUnderline: PropTypes.bool,
	textProps: PropTypes.oneOfType([PropTypes.object]),
};

Anchor.defaultProps = {
	inline: true,
	ariaLabel: '',
	children: null,
	href: undefined,
	textProps: null,
	openInNewTab: false,
	withUnderline: true,
};
