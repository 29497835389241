import PropTypes from 'prop-types';
import { Paragraph } from 'components/common/Typography';
import { ItemWrapper } from './styles';

export const Contact = ({ title, contact }) => {
	return (
		<ItemWrapper>
			<Paragraph variant='body_sm' color='inherit' fontWeight='bold'>
				{title}
			</Paragraph>

			{contact.split('\n').map((item) => (
				<Paragraph key={item} variant='body_sm' color='inherit'>
					{item}
				</Paragraph>
			))}
		</ItemWrapper>
	);
};

Contact.propTypes = {
	title: PropTypes.string,
	contact: PropTypes.string,
};

Contact.defaultProps = {
	title: '',
	contact: '',
};
