import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useMediaQuery as reactMediaQuery } from 'react-responsive';

/**
 * Returns a media query based on the specified breakpoint and threshold key.
 * [Original package](https://www.npmjs.com/package/react-responsive)
 *
 * @param {string} [thresholdKey='max'] - The device threshold for the media query. Default is 'maxWidth'.
 * @param {string} breakpointKey - The key representing a breakpoint. Check theme config for valid options.
 * @returns {object} - A media query object.
 * @throws {Error} - If an invalid breakpointKey is provided.
 */
export const useMediaQuery = (
	thresholdKey = 'maxWidth',
	breakpointKey = 'md'
) => {
	const { breakpoints } = useContext(ThemeContext);

	const getBreakpointValue = (key) => {
		if (Object.prototype.hasOwnProperty.call(breakpoints, key)) {
			return breakpoints[key];
		}
		throw new Error(
			`Invalid breakpoint value! Accepted values are: ${Object.keys(breakpoints).join(', ')}`
		);
	};

	const breakpointValue = getBreakpointValue(breakpointKey);

	return reactMediaQuery({ [`${thresholdKey}`]: breakpointValue });
};
