import styled from 'styled-components';
import { css } from '@styled-system/css';
import { FlexBox } from 'components/common/FlexBox';

export const CookieBannerWrapper = styled(FlexBox)(
	css({
		left: 0,
		bottom: 0,
		zIndex: 1100,
		width: '100%',
		position: 'fixed',
		background: 'white',
		justifyContent: 'center',
	})
);
export const ContentWrapper = styled(FlexBox)(
	css({
		px: 'md',
		py: 'md',
		rowGap: 'sm',
		width: '100%',
		columnGap: 'xxl',
		flexWrap: 'wrap',
		maxWidth: '1440px',
		justifyContent: 'center',
	})
);
