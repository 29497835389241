import PropTypes from 'prop-types';
import { siteMap } from 'utils/siteMap';
import { TeemsLogoFull } from 'assets/app';
import { Icon } from 'components/common/Icon';
import { ContactButton } from 'components/domain/App/ContactButton';

import { MenuItem } from './MenuItem';
import { StyledDrawer, MenuWrapper } from './styles';

export const MobileMenu = ({ open, onClose, pageContext }) => {
	const handleCloseClick = () => {
		onClose(false);
	};

	const {
		page: {
			GENERAL: {
				contents: { GENERAL_SITEMAP, GENERAL_CONTACT_US },
			},
		},
	} = pageContext;

	const sitemap = GENERAL_SITEMAP?.split('\n');

	return (
		<StyledDrawer
			open={open}
			width='100%'
			placement='right'
			title={<TeemsLogoFull />}
			onClose={handleCloseClick}
			closeIcon={<Icon icon='x' />}
		>
			<MenuWrapper>
				{siteMap(sitemap).map(({ to, text }) => (
					<MenuItem
						to={to}
						key={text}
						text={text}
						onClick={handleCloseClick}
					/>
				))}
			</MenuWrapper>
			<ContactButton
				to='/#lets-talk'
				text={GENERAL_CONTACT_US}
				onClick={handleCloseClick}
			/>
		</StyledDrawer>
	);
};

MobileMenu.propTypes = {
	open: PropTypes.bool,
	page: PropTypes.string,
	onClose: PropTypes.func,
	pageContext: PropTypes.oneOfType([PropTypes.object]),
};

MobileMenu.defaultProps = {
	page: '/',
	open: false,
	pageContext: {},
	onClose: () => {},
};
