import PropTypes from 'prop-types';
import { ChildrenType } from 'components/common/Types';
import { Paragraph } from 'components/common/Typography';
import { StyledGatsbyLink, StyledInlineWrapper } from './styles';

/**
 * A custom hyperlink component for an internal Gatsby routing.
 *
 * Based on the [AnchorLink](https://www.gatsbyjs.com/plugins/gatsby-plugin-anchor-links/).
 *
 * @param {Object} LinkProps - The properties of the Link component.
 * @param {string} LinkProps.to - The URL or location that the link should navigate to. For internal routing please start the url with the '/' sign. For a 'smooth scrolling' effect please start with '/#'.
 * @param {boolean} LinkProps.inline - Determines whether the link should be rendered inline or as a paragraph.
 * @param {JSX.Element} LinkProps.children - The content to be rendered within the link.
 * @param {Object} LinkProps.textProps - Additional text properties that should be applied to the link.
 * @param {boolean} LinkProps.openInNewTab - Determines whether the link should open in a new tab.
 * @param {boolean} LinkProps.withUnderline - Determines whether the link should have an underline when being hovered.
 * @returns {JSX.Element} - The rendered Link component.
 */
export const Link = ({
	to,
	inline,
	children,
	textProps,
	openInNewTab,
	withUnderline,
}) => {
	const baseComponent = (
		<StyledGatsbyLink
			to={to}
			withUnderline={withUnderline}
			gatsbyLinkProps={
				openInNewTab ? { target: '_blank', rel: 'noreferrer' } : null
			}
		>
			{children}
		</StyledGatsbyLink>
	);

	return inline ? (
		<StyledInlineWrapper {...textProps}>
			{baseComponent}
		</StyledInlineWrapper>
	) : (
		<Paragraph {...textProps}>{baseComponent}</Paragraph>
	);
};

Link.propTypes = {
	to: PropTypes.string,
	inline: PropTypes.bool,
	children: ChildrenType,
	openInNewTab: PropTypes.bool,
	withUnderline: PropTypes.bool,
	textProps: PropTypes.oneOfType([PropTypes.object]),
};

Link.defaultProps = {
	inline: true,
	to: undefined,
	children: null,
	textProps: null,
	openInNewTab: false,
	withUnderline: true,
};
