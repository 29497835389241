export const variants = {
	display: {
		lineHeight: '81px',
		letterSpacing: '-0.05em',
		fontSize: ['mobile.xl', 'mobile.xl', 'desktop.xxxl'],
	},
	display_sm: {
		lineHeight: '31px',
		letterSpacing: '0.1em',
		fontSize: ['mobile.lg', 'mobile.lg', 'desktop.xl'],
	},
	body: {
		lineHeight: '31px',
		letterSpacing: '0.1em',
		fontSize: ['mobile.xs', 'mobile.xs', 'desktop.xs'],
	},
	captions: {
		lineHeight: '48px',
		letterSpacing: '0.6em',
		textTransform: 'uppercase',
		fontSize: ['mobile.md', 'mobile.md', 'desktop.md'],
	},
	captions_sm: {
		lineHeight: '28px',
		letterSpacing: '0.3em',
		textTransform: 'uppercase',
		fontSize: ['mobile.xxs', 'mobile.xxs', 'desktop.xxs'],
	},
	body_sm: {
		lineHeight: '19px',
		letterSpacing: '0.1em',
		fontSize: ['mobile.xxs', 'mobile.xxs', 'desktop.xxs'],
	},
};
