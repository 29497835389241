import { theme } from 'theme/theme';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { FlexBox } from 'components/common/FlexBox';

export const StyledHeader = styled.header(
	css({
		top: 0,
		zIndex: 100,
		right: '50%',
		width: '100%',
		maxWidth: '1440px',
		position: 'absolute',
		transform: 'translateX(50%)',
		pb: ['sm', 'sm', 'sm', 'xxl'],
		pt: ['xxxl', 'xxxl', 'xxxl', 'xxl'],
		px: ['md', 'md', 'md', 'md', `${theme.space[11]}`],
	})
);

export const NavbarLinkWrapper = styled(FlexBox)(({ color }) =>
	css({
		boxShadow: 'none',
		borderRadius: '24px',
		alignItems: 'center',
		justifyContent: 'center',
		transition: 'all 0.4s ease-in',

		'&, a': {
			color,
			height: '35px',
			minWidth: '120px',
		},

		a: {
			p: 'xs',
			height: '100%',
		},

		'&:hover': {
			boxShadow: '0 -1px',
		},
	})
);

export const HeaderContentWrapper = styled(FlexBox)(
	css({
		width: '100%',
		position: 'relative',
		alignItems: 'center',
		justifyContent: ['center', 'center', 'center', 'space-between'],
	})
);

export const NavbarMenu = styled(FlexBox)(
	css({
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: ['xs', 'xs', 'xs', 'xs', 'lg'],
		width: ['fit-content', 'fit-content', 'fit-content', '80%'],
	})
);

export const MobileMenuIconWrapper = styled(FlexBox)(
	css({
		right: 0,
		position: 'absolute',
	})
);
