import PropTypes from 'prop-types';

export const TeemsLogoFull = ({ color }) => {
	const colorsMap = {
		white: 'white',
		blue: '#1E80DB',
	};

	return (
		<svg
			width='160'
			height='25'
			viewBox='0 0 160 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_355_53)'>
				<path
					d='M45.4062 21.9585C44.924 22.1844 44.3504 22.2978 43.6854 22.2978C43.1497 22.2978 42.7773 22.1414 42.3486 21.8284C41.9198 21.5164 41.8158 21.047 41.8158 20.4211V8.13086H40.1436V20.8103C40.1436 21.3071 40.1513 21.7374 40.3234 22.1042C40.4945 22.4709 40.6909 22.7829 40.9913 23.0421C41.2908 23.3012 41.6194 23.4949 42.017 23.6249C42.4137 23.754 42.8269 23.8186 43.2771 23.8186C43.7272 23.8186 44.1404 23.7756 44.5264 23.6895C44.9123 23.6034 45.2575 23.5066 45.5686 23.3981C45.8797 23.2905 46.1354 23.177 46.3396 23.0597C46.5428 22.9413 46.6867 22.8602 46.7722 22.8162L46.29 21.5223C46.183 21.5859 45.8885 21.7326 45.4062 21.9585Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M37.9152 6.16821H34.0127V8.13101H37.9152V6.16821Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M160 6.16821H156.934V8.13101H160V6.16821Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M62.079 9.08453C61.3498 8.29726 60.4816 7.67233 59.4744 7.20877C58.4662 6.74521 57.3734 6.51343 56.195 6.51343C55.0371 6.51343 53.9599 6.74032 52.9634 7.19312C51.9658 7.64593 51.1035 8.26596 50.3743 9.05323C49.6451 9.8405 49.0715 10.7569 48.6535 11.8033C48.2354 12.8497 48.0264 13.9646 48.0264 15.1509C48.0264 16.3587 48.2354 17.4912 48.6535 18.5474C49.0715 19.6036 49.65 20.5366 50.3899 21.3454C51.1297 22.1542 52.0028 22.7909 53.011 23.2535C54.0182 23.717 55.112 23.9488 56.2903 23.9488C57.0623 23.9488 57.8119 23.8461 58.541 23.6417C59.2692 23.4373 59.9401 23.1517 60.5506 22.785C61.1612 22.4183 61.6969 21.9821 62.1587 21.4745C62.6195 20.9689 62.9676 20.4124 63.2039 19.809L61.7893 19.4217C61.5744 19.8961 61.2798 20.3332 60.9045 20.7313C60.5292 21.1303 60.1005 21.4696 59.6183 21.7503C59.136 22.031 58.6159 22.251 58.0588 22.4134C57.5007 22.5747 56.933 22.6559 56.3545 22.6559C55.4756 22.6559 54.6502 22.4838 53.8782 22.1386C53.1063 21.7933 52.4257 21.319 51.8365 20.7146C51.2474 20.1122 50.7593 19.3142 50.3733 18.4839C49.9874 17.6545 49.7628 16.8242 49.6986 15.7035H64.3618C64.3832 15.7035 64.1158 15.7181 64.1158 15.5558C64.1158 15.3944 64.1158 15.2604 64.1158 15.1519C64.1158 13.9881 64.0516 12.883 63.6443 11.8366C63.2369 10.7882 62.8072 9.87278 62.079 9.08453ZM49.6675 14.2991C49.7317 14.0184 49.9407 12.5622 50.2946 11.7329C50.6485 10.9026 51.12 10.2581 51.7092 9.66447C52.2983 9.07181 52.9789 8.6415 53.7509 8.30704C54.5228 7.97257 55.3473 7.82196 56.2271 7.82196C57.106 7.82196 57.9314 7.998 58.7034 8.33149C59.4754 8.66595 60.1559 9.13343 60.7451 9.72608C61.3343 10.3197 61.8165 10.8938 62.1918 11.7133C62.5661 12.5329 62.7868 13.1774 62.8509 14.2981H49.6675V14.2991Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M80.8256 9.08453C80.0964 8.29726 79.2282 7.67233 78.221 7.20877C77.2128 6.74521 76.12 6.51343 74.9407 6.51343C73.7827 6.51343 72.7055 6.74032 71.709 7.19312C70.7124 7.64593 69.8491 8.26596 69.1199 9.05323C68.3907 9.8405 67.8171 10.7569 67.4 11.8033C66.982 12.8497 66.7729 13.9646 66.7729 15.1509C66.7729 16.3587 66.982 17.4912 67.4 18.5474C67.8181 19.6036 68.3975 20.5366 69.1364 21.3454C69.8763 22.1542 70.7494 22.7909 71.7576 23.2535C72.7648 23.717 73.8586 23.9488 75.0369 23.9488C75.8089 23.9488 76.5585 23.8461 77.2876 23.6417C78.0158 23.4373 78.6867 23.1517 79.2972 22.785C79.9078 22.4183 80.4435 21.9821 80.9053 21.4745C81.3661 20.9689 81.7142 20.4124 81.9504 19.809L80.5358 19.4217C80.322 19.8961 80.0264 20.3332 79.6511 20.7313C79.2758 21.1303 78.8471 21.4696 78.3649 21.7503C77.8826 22.031 77.3625 22.251 76.8054 22.4134C76.2473 22.5747 75.6796 22.6559 75.1011 22.6559C74.2222 22.6559 73.3968 22.4838 72.6248 22.1386C71.8529 21.7933 71.1723 21.319 70.5831 20.7146C69.9939 20.1122 69.5059 19.3142 69.1199 18.4839C68.7339 17.6545 68.5094 16.8242 68.4452 15.7035H83.1074C83.1288 15.7035 83.0685 15.7181 83.0685 15.5558C83.0685 15.3944 83.0685 15.2604 83.0685 15.1519C83.0685 13.9881 82.9003 12.883 82.4929 11.8366C82.0866 10.7882 81.5538 9.87278 80.8256 9.08453ZM68.4131 14.2991C68.4773 14.0184 68.6863 12.5622 69.0402 11.7329C69.3941 10.9026 69.8656 10.2581 70.4548 9.66447C71.044 9.07181 71.7245 8.6415 72.4965 8.30704C73.2684 7.97257 74.0929 7.82196 74.9727 7.82196C75.8516 7.82196 76.6771 7.998 77.449 8.33149C78.221 8.66595 78.9015 9.13343 79.4907 9.72608C80.0799 10.3197 80.5621 10.8938 80.9374 11.7133C81.3127 12.5329 81.5324 13.1774 81.5966 14.2981H68.4131V14.2991Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M106.163 6.51343C103.375 6.51343 101.19 8.0332 99.6031 11.0747C99.431 9.7163 98.906 8.61608 98.0281 7.77502C97.1482 6.93396 96.002 6.51343 94.5874 6.51343C93.237 6.51343 92.0926 6.89093 90.9785 7.64593C89.8633 8.40092 88.9232 9.45714 88.3661 10.8165V7.01024H86.6938V23.8334H88.3661V13.2087C88.3661 12.4331 88.8454 11.727 89.2421 11.0904C89.6387 10.4547 90.0743 9.90505 90.5886 9.44149C91.1029 8.97793 91.6561 8.62195 92.2667 8.37452C92.8782 8.12709 93.5209 8.00289 94.2072 8.00289C95.5577 8.00289 96.5338 8.50361 97.1774 9.50702C97.821 10.5104 98.1224 12.0898 98.1224 14.2463V23.8344H99.7947V13.2429C99.7947 12.4674 100.289 11.7603 100.665 11.1246C101.04 10.4889 101.478 9.93928 101.992 9.47474C102.506 9.01118 103.07 8.64933 103.692 8.39114C104.314 8.13198 104.966 8.00289 105.652 8.00289C107.003 8.00289 107.821 8.50948 108.475 9.52266C109.128 10.5368 109.272 12.1104 109.272 14.2453V23.8334H111.223V13.9529C111.223 11.6028 110.843 9.77498 110.06 8.47036C109.276 7.16574 107.985 6.51343 106.163 6.51343Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M126.147 15.9577C125.6 15.6135 124.92 15.3221 124.105 15.0844C123.29 14.8477 122.358 14.5993 121.307 14.3412C120.536 14.1465 119.871 13.9685 119.313 13.8062C118.755 13.6448 118.3 13.4561 117.947 13.2399C117.593 13.0258 117.336 12.7666 117.175 12.4644C117.015 12.1622 116.934 11.7642 116.934 11.2674C116.934 10.664 117.052 10.1407 117.287 9.69869C117.522 9.25763 117.844 8.89578 118.252 8.6151C118.659 8.3354 119.136 8.13002 119.683 8.00093C120.229 7.87184 120.813 7.80631 121.435 7.80631C122.444 7.80631 123.392 7.96768 124.281 8.29139C125.17 8.6151 125.894 9.10018 126.451 9.74662L127.351 8.87328C126.793 8.18283 126.012 7.61756 125.004 7.17552C123.995 6.73347 122.838 6.51245 121.532 6.51245C120.696 6.51245 119.908 6.61514 119.168 6.81953C118.429 7.02491 117.774 7.332 117.206 7.74079C116.638 8.15056 116.193 8.66889 115.872 9.29284C115.551 9.91874 115.39 10.6512 115.39 11.4923C115.39 12.1603 115.503 12.7109 115.727 13.1412C115.952 13.5725 116.29 13.9392 116.74 14.2414C117.191 14.5436 117.763 14.8018 118.46 15.0179C119.157 15.234 119.977 15.4492 120.92 15.6644C121.798 15.8805 122.57 16.0839 123.235 16.2785C123.899 16.4731 124.457 16.6942 124.907 16.9426C125.357 17.191 125.695 17.4922 125.92 17.8482C126.146 18.2042 126.258 18.6403 126.258 19.1577C126.258 20.2569 125.812 21.1146 124.924 21.7298C124.033 22.3439 122.86 22.651 121.402 22.651C120.244 22.651 119.184 22.4574 118.218 22.0691C117.254 21.6809 116.322 21.0765 115.421 20.2569L114.617 21.3572C115.496 22.2207 116.525 22.8672 117.704 23.2975C118.883 23.7287 120.116 23.9439 121.402 23.9439C123.353 23.9439 124.912 23.497 126.081 22.6011C127.248 21.7073 127.833 20.5044 127.833 18.9953C127.833 18.2619 127.688 17.6584 127.399 17.1832C127.111 16.7137 126.693 16.3039 126.147 15.9577Z'
					fill={colorsMap[color]}
				/>
				<path d='M146.899 0H145.227V2.80386H146.899V0Z' fill='white' />
				<path
					d='M146.899 7.00928H145.227V23.8324H146.899V7.00928Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M158.056 21.9585C157.574 22.1844 157.001 22.2978 156.337 22.2978C155.8 22.2978 155.547 22.1414 155.118 21.8284C154.689 21.5164 154.703 21.047 154.703 20.4211V8.13086H152.752V20.8103C152.752 21.3071 152.78 21.7374 152.952 22.1042C153.123 22.4709 153.33 22.7829 153.632 23.0421C153.931 23.3012 154.266 23.4949 154.663 23.6249C155.059 23.754 155.475 23.8186 155.925 23.8186C156.376 23.8186 156.791 23.7756 157.178 23.6895C157.564 23.6034 157.909 23.5066 158.22 23.3981C158.53 23.2905 158.787 23.177 158.99 23.0597C159.193 22.9413 159.338 22.8602 159.425 22.8162L158.941 21.5223C158.834 21.5859 158.539 21.7326 158.056 21.9585Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M133.799 18.9767L137.427 21.2084L133.799 23.4411V24.022L138.537 21.3776V21.0607L133.799 18.4055V18.9767Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M154.704 1.12183H152.753V6.16916H154.704V1.12183Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M41.8168 1.12183H40.1445V6.16916H41.8168V1.12183Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M8.54785 23.9478L0 9.54909L1.69557 5.48657L9.4705 17.236L11.5627 13.6312L16.3043 13.5891L10.6751 23.941L8.54785 23.9478Z'
					fill={colorsMap[color]}
				/>
				<path
					d='M21.4803 4.2133L17.7625 11.0464L17.7178 11.0885L12.9947 11.0846L16.2001 5.49934L6.45449 5.52086L5.77393 2.23877H21.4813L21.4803 4.2133Z'
					fill={colorsMap[color]}
				/>
			</g>
			<defs>
				<clipPath id='clip0_355_53'>
					<rect width='160' height='24.022' fill={colorsMap[color]} />
				</clipPath>
			</defs>
		</svg>
	);
};

TeemsLogoFull.propTypes = {
	color: PropTypes.oneOf(['white', 'blue']),
};

TeemsLogoFull.defaultProps = {
	color: 'blue',
};
