import PropTypes from 'prop-types';
import { theme } from 'theme/theme';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import styledSystemPropTypes from '@styled-system/prop-types';
import { flexbox, space, compose, layout, position } from 'styled-system';

const gapSizes = [
	'unset',
	'0',
	'xs',
	'sm',
	'md',
	'lg',
	'xl',
	'xxl',
	'xxxl',
	'4xl',
	'5xl',
	'6xl',
	'7xl',
	'8xl',
];

export const FlexBox = styled.div(
	({
		width,
		rowGap,
		maxWidth,
		columnGap,
		alignItems,
		flexDirection,
		justifyContent,
	}) =>
		css({
			display: 'flex',
			width: width || 'fit-content',
			maxWidth: maxWidth || 'unset',
			alignItems: alignItems || 'unset',
			flexDirection: flexDirection || 'row',
			justifyContent: justifyContent || 'unset',
			rowGap: rowGap ? theme.space[rowGap] : 'unset',
			columnGap: columnGap ? theme.space[columnGap] : 'unset',
		}),
	compose(flexbox, space, layout, position)
);

FlexBox.propTypes = {
	columnGap: PropTypes.oneOfType([
		PropTypes.oneOf(gapSizes),
		PropTypes.arrayOf(PropTypes.oneOf(gapSizes)),
	]),
	rowGap: PropTypes.oneOfType([
		PropTypes.oneOf(gapSizes),
		PropTypes.arrayOf(PropTypes.oneOf(gapSizes)),
	]),
	...styledSystemPropTypes.space,
	...styledSystemPropTypes.layout,
	...styledSystemPropTypes.flexbox,
	...styledSystemPropTypes.position,
};
