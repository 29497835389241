import PropTypes from 'prop-types';
import { Svg as SvgComponent } from './Svg';
import { Image as ImageComponent } from './Image';

/**
 * An image component
 *
 * @param {Object} ext - The extension of the image.
 *
 * @returns {JSX.Element} - The rendered image component.
 */
export const Image = ({ ext, ...props }) => {
	return ext === '.svg' ? (
		<SvgComponent {...props} />
	) : (
		<ImageComponent {...props} />
	);
};

Image.propTypes = {
	ext: PropTypes.string,
};

Image.defaultProps = {
	ext: '',
};
