import PropTypes from 'prop-types';
import { Link } from 'components/common/Link';
import { Paragraph } from 'components/common/Typography';
import { ItemWrapper, InnerWrapper } from './styles';

export const Offer = ({ offerItems, offer }) => {
	const links = offerItems.map(
		({ title, slug, landing_page_categories: categories }) => {
			const basePath = `/${slug}`;

			const to =
				categories.length > 1
					? `${basePath}/categories`
					: `${basePath}/category/${categories[0].slug}`;

			return (
				<Link
					to={to}
					key={title}
					inline={false}
					textProps={{ variant: 'body_sm', color: 'inherit' }}
				>
					{title.toLowerCase()}
				</Link>
			);
		}
	);

	return (
		<ItemWrapper>
			<Paragraph variant='body_sm' color='inherit' fontWeight='bold'>
				{offer}
			</Paragraph>

			<InnerWrapper>{links}</InnerWrapper>
		</ItemWrapper>
	);
};

Offer.propTypes = {
	offer: PropTypes.string,
	offerItems: PropTypes.oneOfType([PropTypes.array]),
};

Offer.defaultProps = {
	offer: '',
	offerItems: [],
};
