import { ThemeProvider } from 'theme/index';
import { ChildrenType } from 'components/common/Types';
import { PageContainer } from 'components/common/Containers';

export const BlankLayout = ({ children }) => {
	return (
		<ThemeProvider>
			<PageContainer minHeight='100vh'>{children}</PageContainer>
		</ThemeProvider>
	);
};

BlankLayout.propTypes = {
	children: ChildrenType,
};

BlankLayout.defaultProps = {
	children: [],
};
