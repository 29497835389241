import PropTypes from 'prop-types';

/**
 * An svg image component
 *
 * @param {string} src - The source of the image.
 * @param {string} alt - The alternative alt attribute for the image.
 * @param {number} width - The width attriburte for image
 * @param {number} height - The height attriburte for image
 *
 * @returns {JSX.Element} - The rendered image component.
 *
 */

export const Svg = ({ src, alt, width, height }) =>
	src && <img src={src} alt={alt} width={width} height={height} />;

Svg.propTypes = {
	alt: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	src: PropTypes.string.isRequired,
};

Svg.defaultProps = {
	alt: '',
	width: 100,
	height: 100,
};
