import PropTypes from 'prop-types';
import { Image } from 'components/common/Image';
import { FlexBox } from 'components/common/FlexBox';
import { FindUs, SiteMap, Offer, Contact } from './Items';
import {
	Copyright,
	ItemsWrapper,
	InnerWrapper,
	FooterContainer,
} from './styles';

export const Footer = ({ page, pageContext }) => {
	const {
		landing_page_blog: blogs,
		page: {
			GENERAL: {
				contents: { GENERAL_SITEMAP },
			},
			FOOTER: {
				contents: {
					FOOTER_MENU,
					FOOTER_OFFER,
					FOOTER_ADDRESS,
					FOOTER_CONTACT,
					FOOTER_FIND_US,
					FOOTER_COPYRIGHT,
					FOOTER_CONTACT_INFO,
					FOOTER_LINKEDIN_ADDRESS,
					FOOTER_LINKED_IN_ARIA_LABEL,
				},
				media: { FOOTER_LOGO },
			},
		},
	} = pageContext;

	const sitemap = GENERAL_SITEMAP.split('\n');

	return (
		<FooterContainer id='footer'>
			<InnerWrapper>
				<FlexBox width='100%'>
					<Image {...FOOTER_LOGO} />
				</FlexBox>
				<ItemsWrapper>
					<Contact
						title={FOOTER_CONTACT}
						address={FOOTER_ADDRESS}
						contact={FOOTER_CONTACT_INFO}
					/>
					<SiteMap page={page} menu={FOOTER_MENU} sitemap={sitemap} />
					<Offer offer={FOOTER_OFFER} offerItems={blogs} />
					<FindUs
						title={FOOTER_FIND_US}
						linkedinLink={FOOTER_LINKEDIN_ADDRESS}
						linkedinAria={FOOTER_LINKED_IN_ARIA_LABEL}
					/>
				</ItemsWrapper>
				<Copyright variant='body_sm'>{FOOTER_COPYRIGHT}</Copyright>
			</InnerWrapper>
		</FooterContainer>
	);
};

Footer.propTypes = {
	page: PropTypes.string.isRequired,
	pageContext: PropTypes.oneOfType([PropTypes.object]),
};

Footer.defaultProps = {
	pageContext: {},
};
