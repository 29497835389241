import styled from 'styled-components';
import { css } from '@styled-system/css';
import { flexbox, space } from 'styled-system';
import {
	StyledButtonBase,
	buttonBorderRadius,
} from 'components/common/Button/styles';

export const ButtonBase = styled(StyledButtonBase)(
	css({
		color: 'inherit',
		background: 'transparent',
		boxShadow: '0px 4px 8px 0px rgba(30, 65, 134, 0.25) inset',
	}),
	space,
	flexbox
);

export const ButtonBorderWrapper = styled.div(
	css({
		padding: '1px',
		color: 'blue_300',
		overflow: 'hidden',
		position: 'relative',
		borderRadius: buttonBorderRadius,
		transition: 'opacity 0.3s ease-out',
		backgroundImage:
			'linear-gradient(286.2deg, #F5F5F5 -7%, rgba(255, 255, 255, 0) 70.69%)',
		boxShadow:
			'30px 40px 20px rgba(38, 105, 226, 0.01), 17px 23px 17px rgba(38, 105, 226, 0.05), 8px 10px 13px rgba(38, 105, 226, 0.09), 2px 3px 7px rgba(38, 105, 226, 0.1)',

		'.button-border__overlay': {
			top: 0,
			left: 0,
			opacity: 0,
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			position: 'absolute',
			borderRadius: buttonBorderRadius,
			transition: 'opacity 0.3s ease-out',
			backgroundImage:
				'linear-gradient(286.2deg, #256CD0 -7%, rgba(84, 133, 203, 0) 70.69%)',
		},
		'.button-bg__overlay': {
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			position: 'absolute',
			transition: 'opacity 0.45s',
			borderRadius: buttonBorderRadius,
			backgroundImage:
				'linear-gradient(243.95deg, #C9D0DF 17.34%, rgba(215, 233, 246, 0.6) 94.88%), linear-gradient(286.2deg, #F5F5F5 -7%, rgba(255, 255, 255, 0) 70.69%)',
		},

		'.button-animation__overlay': {
			left: '0',
			zIndex: -2,
			top: '-57%',
			width: '100%',
			height: '220%',
			borderRadius: '250%',
			position: 'absolute',
			background: '#5F95DD',
			transform: 'scale(0, 0)',
			transition: 'all 0.3s ease-out',
		},

		'&:hover': {
			'.button-border__overlay': {
				opacity: 1,
			},
			'.button-bg__overlay': {
				opacity: 0,
			},
			'.ant-btn-default:hover': {
				color: 'white_100',
				background: 'transparent',
			},

			'.button-animation__overlay': {
				transform: 'scale(1, 1)',
			},
		},
	})
);
