import { palette } from './palette';

export const colors = {
	...palette,
	modes: {
		light: {
			primary: palette['blue-2'],
			category_hero_bg: palette['blue-7'],
			document_bg: palette['blue-matte-1'],
			danger: palette.danger,
			// black
			black_100: palette['black-1'],
			black_200: palette['black-2'],
			black_300: palette['black-3'],
			black_400: palette['black-4'],
			black_500: palette['black-5'],
			black_600: palette['black-6'],
			black_700: palette['black-7'],
			// white
			white_100: palette['white-1'],
			white_200: palette['white-2'],
			white_300: palette['white-3'],
			white_400: palette['white-4'],
			white_500: palette['white-5'],
			white_600: palette['white-6'],
			white_700: palette['white-7'],
			// blue
			blue_100: palette['blue-1'],
			blue_200: palette['blue-2'],
			blue_300: palette['blue-3'],
			blue_400: palette['blue-4'],
			blue_500: palette['blue-5'],
			blue_600: palette['blue-6'],
			blue_700: palette['blue-7'],
			// blue-matte
			blue_matte: palette['blue-matte-1'],
			blue_matte_200: palette['blue-matte-2'],
			blue_matte_300: palette['blue-matte-3'],
			blue_matte_400: palette['blue-matte-4'],
			blue_matte_500: palette['blue-matte-5'],
			blue_matte_600: palette['blue-matte-6'],
			blue_matte_700: palette['blue-matte-7'],
			blue_matte_800: palette['blue-matte-8'],
			blue_matte_900: palette['blue-matte-9'],
			blue_matte_1000: palette['blue-matte-10'],
		},
		dark: {},
	},
};
