import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

/**
 * An image component built with help of the [GatsbyImage](https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#gatsbyimage).
 *
 * @param {Object} gatsbyImageData - The gatsby image data object.
 * @param {string} alt - The alternative alt attribute for the image.
 * @param {string} className - The CSS class name(s) for the image.
 * @param {Object} style - The inline CSS styles for the image.
 *
 * @returns {JSX.Element} - The rendered image component.
 */
export const Image = ({ gatsbyImageData, alt, className, style }) =>
	gatsbyImageData && (
		<GatsbyImage
			style={style}
			className={className}
			image={gatsbyImageData}
			alt={alt || ''}
		/>
	);

Image.propTypes = {
	alt: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
	gatsbyImageData: PropTypes.oneOfType([PropTypes.object]),
};

Image.defaultProps = {
	alt: '',
	style: {},
	className: '',
	gatsbyImageData: {},
};
