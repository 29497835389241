import styled from 'styled-components';
import { css } from '@styled-system/css';

export const PageContainer = styled.div(({ minHeight }) =>
	css({
		width: '100%',
		overflow: 'hidden',
		position: 'relative',
		minHeight: minHeight || 'unset',
	})
);

export const PageContentContainer = styled.main(
	css({
		width: '100%',
		margin: '0 auto',
		maxWidth: '1440px',
		position: 'relative',
		flexDirection: 'column',
	})
);
