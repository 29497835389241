import PropTypes from 'prop-types';
import { siteMap } from 'utils/siteMap';
import { Icon } from 'components/common/Icon';
import { Image } from 'components/common/Image';
import { useMediaQuery } from 'utils/useMediaQuery';
import { FlexBox } from 'components/common/FlexBox';
import { ContactButton } from 'components/domain/App/ContactButton';
import { NavbarLink } from './NavbarLink';

import {
	NavbarMenu,
	StyledHeader,
	HeaderContentWrapper,
	MobileMenuIconWrapper,
} from './styles';

export const Header = ({ openMobileMenuHandler, pageContext, theme, page }) => {
	const colorsMap = {
		white: 'white',
		blue: 'primary',
	};

	const {
		page: {
			GENERAL: {
				contents: { GENERAL_SITEMAP, GENERAL_CONTACT_US },
			},
			HEADER: {
				media: { HEADER_LOGO },
			},
		},
	} = pageContext;

	const sitemap = GENERAL_SITEMAP?.split('\n');

	const isMobile = useMediaQuery('maxWidth', 'md');

	const onOpenMobileMenuClick = () => {
		openMobileMenuHandler(true);
	};

	return (
		<StyledHeader id='home'>
			<HeaderContentWrapper>
				<Image {...HEADER_LOGO} />

				{isMobile && (
					<MobileMenuIconWrapper>
						<Icon
							icon='menu'
							color={colorsMap[theme]}
							onClick={onOpenMobileMenuClick}
						/>
					</MobileMenuIconWrapper>
				)}
				{!isMobile && (
					<NavbarMenu>
						<FlexBox>
							{siteMap(sitemap, page, true).map(
								({ to, text }) => (
									<NavbarLink
										to={to}
										key={text}
										text={text}
										color={colorsMap[theme]}
									/>
								)
							)}
						</FlexBox>
						<ContactButton
							to='/#lets-talk'
							text={GENERAL_CONTACT_US}
						/>
					</NavbarMenu>
				)}
			</HeaderContentWrapper>
		</StyledHeader>
	);
};

Header.propTypes = {
	page: PropTypes.string,
	openMobileMenuHandler: PropTypes.func,
	theme: PropTypes.oneOf(['white', 'blue']),
	pageContext: PropTypes.oneOfType([PropTypes.object]),
};

Header.defaultProps = {
	page: '/',
	theme: 'blue',
	pageContext: {},
	openMobileMenuHandler: () => {},
};
