export const variants = {
	1: {
		letterSpacing: '0.02em',
		lineHeight: ['70px', '70px', '143px'],
		fontSize: ['mobile.h1', 'mobile.h1', 'desktop.h1'],
	},
	2: {
		letterSpacing: '0.02em',
		lineHeight: ['62px', '62px', '77px'],
		fontSize: ['mobile.xxl', 'mobile.xxl', 'desktop.xxxl'],
	},
	3: {
		letterSpacing: '-0.05em',
		lineHeight: ['39px', '39px', '62px'],
		fontSize: ['mobile.lg', 'mobile.lg', 'desktop.xxl'],
	},
	4: {
		lineHeight: ['34px', '34px', '39px'],
		letterSpacing: ['normal', 'normal', '-0.05em'],
		fontSize: ['mobile.sm', 'mobile.sm', 'desktop.lg'],
	},
	5: {
		lineHeight: '34px',
		fontSize: ['mobile.xs', 'mobile.xs', 'desktop.sm'],
	},
	6: {
		lineHeight: '34px',
		fontSize: 'desktop.sm',
	},
};
