import PropTypes from 'prop-types';
import {
	StyledIcon,
	StyledFeatherIcon,
	StyledIconButtonWrapper,
} from './styles';

const renderIcon = (icon, size, color) =>
	typeof icon === 'object' ? (
		<StyledIcon size={size} color={color}>
			{icon}
		</StyledIcon>
	) : (
		<StyledFeatherIcon icon={icon} size={size} color={color} />
	);

const renderButtonIcon = (icon, size, color, onClick) => (
	<StyledIconButtonWrapper type='button' onClick={onClick} color={color}>
		{renderIcon(icon, size, color)}
	</StyledIconButtonWrapper>
);

export const Icon = ({ icon, size, onClick, color }) =>
	onClick
		? renderButtonIcon(icon, size, color, onClick)
		: renderIcon(icon, size, color);

Icon.propTypes = {
	color: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
	icon: '',
	size: 24,
	color: 'white',
	onClick: undefined,
};
