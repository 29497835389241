import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { palette } from 'theme/palette';
import isPropValid from '@emotion/is-prop-valid';
import * as StyledComponents from 'styled-components';
import { ChildrenType } from 'components/common/Types';
import { getTheme } from './theme';
import { GlobalStyle } from './GlobalStyle';

const shouldForwardProp = (propName, target) => {
	if (typeof target === 'string') {
		return isPropValid(propName);
	}
	return true;
};

export const ThemeProvider = ({ theme, children }) => {
	return (
		<StyledComponents.StyleSheetManager
			shouldForwardProp={shouldForwardProp}
		>
			<StyledComponents.ThemeProvider theme={getTheme(theme)}>
				<GlobalStyle />
				<ConfigProvider
					theme={{
						token: {
							colorError: palette.danger,
						},
					}}
				>
					{children}
				</ConfigProvider>
			</StyledComponents.ThemeProvider>
		</StyledComponents.StyleSheetManager>
	);
};

ThemeProvider.propTypes = {
	children: ChildrenType,
	theme: PropTypes.oneOf(['light']),
};

ThemeProvider.defaultProps = {
	children: [],
	theme: 'light',
};
