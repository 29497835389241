import { theme } from 'theme/theme';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { FlexBox } from 'components/common/FlexBox';

export const ItemWrapper = styled(FlexBox)(
	css({
		mr: 'auto',
		color: 'inherit',
		flexDirection: 'column',
		rowGap: ['xs', 'xs', 'xs', 'sm'],
		width: ['100%', '50%', '50%', '25%'],
		pr: [0, 'xxl', `${theme.space[10]}`],
	})
);

export const InnerWrapper = styled(FlexBox)(
	css({
		mr: 'auto',
		width: '100%',
		color: 'inherit',
		flexDirection: 'column',
		rowGap: ['xs', 'xs', 'xs', 'sm'],
	})
);
