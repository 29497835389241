import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useLocation } from '@reach/router';
import { Button } from 'components/common/Button';
import { Paragraph } from 'components/common/Typography';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { CookieBannerWrapper, ContentWrapper } from './styles';

export const CookieBanner = ({ agreeButtonText, agreementText }) => {
	const location = useLocation();
	const [cookies, setCookie] = useCookies();

	if (cookies['teems-it-gtm-cookie']) {
		return '';
	}

	const acceptCookieHandle = () => {
		setCookie('teems-it-gtm-cookie', true);

		initializeAndTrack(location);
	};

	return (
		<CookieBannerWrapper>
			<ContentWrapper>
				<Paragraph variant='body_sm'>{agreementText}</Paragraph>
				<Button
					ml='auto'
					onClick={acceptCookieHandle}
					text={agreeButtonText}
				/>
			</ContentWrapper>
		</CookieBannerWrapper>
	);
};

CookieBanner.propTypes = {
	agreementText: PropTypes.string,
	agreeButtonText: PropTypes.string,
};

CookieBanner.defaultProps = {
	agreementText: '',
	agreeButtonText: '',
};
