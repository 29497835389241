import PropTypes from 'prop-types';
import { Link } from 'components/common/Link';
import { NavbarLinkWrapper } from './styles';

export const NavbarLink = ({ text, to, color, onClick }) => {
	return (
		<NavbarLinkWrapper color={color}>
			<Link
				to={to}
				inline={false}
				withUnderline={false}
				textProps={{
					variant: 'body_sm',
					textAlign: 'center',
					justifyContent: 'center',
				}}
				onAnchorLinkClick={onClick}
			>
				{text}
			</Link>
		</NavbarLinkWrapper>
	);
};

NavbarLink.propTypes = {
	color: PropTypes.string,
	onClick: PropTypes.func,
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

NavbarLink.defaultProps = {
	color: 'white',
	onClick: () => {},
};
