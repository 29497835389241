import styled from 'styled-components';
import { css } from '@styled-system/css';

const linkProps = {
	color: 'inherit',
	textDecoration: 'none',
};

export const StyledInlineWrapper = styled.span(({ color }) =>
	css({
		width: 'fit-content',
		color: color || 'inherit',
	})
);

export const StyledAnchor = styled.a(({ withUnderline }) =>
	css({
		...linkProps,

		'&:hover': {
			textDecoration: withUnderline ? 'underline' : 'none',
		},
	})
);
