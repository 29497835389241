/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import styledSystemPropTypes from '@styled-system/prop-types';
import { variant, space, layout, system, compose } from 'styled-system';
import { variants } from './headingVariants';

const HeadingBase = ({
	level,
	color,
	textAlign,
	whiteSpace,
	as: Component = `h${level}`,
	...props
}) => <Component {...props} />;

export const Heading = styled(HeadingBase)(
	({ color, whiteSpace }) =>
		css({
			margin: 0,
			fontWeight: 'normal',
			color: color || 'primary',
			whiteSpace: whiteSpace || 'unset',
			fontFamily: `"Stardom", sans-serif`,
		}),
	variant({
		variants,
		prop: 'level',
	}),
	system({
		textAlign: true,
	}),
	compose(space, layout)
);
Heading.propTypes = {
	...styledSystemPropTypes.space,
	...styledSystemPropTypes.layout,
	color: PropTypes.string,
	children: PropTypes.node,
	textAlign: PropTypes.string,
	level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};
