/* eslint-disable prefer-destructuring */
const breakpoints = ['576px', '768px', '992px', '1200px', '1600px'];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const space = [
	'0',
	'8px', // xs
	'16px', // sm
	'24px', // md
	'32px', // lg
	'40px', // xl
	'48px', // xxl
	'56px', // xxxl
	'64px', // 4xl
	'72px', // 5xl
	'80px', // 6xl
	'104px', // 7xl
	'120px', // 8xl
];

space.xs = space[1];
space.sm = space[2];
space.md = space[3];
space.lg = space[4];
space.xl = space[5];
space.xxl = space[6];
space.xxxl = space[7];
space['4xl'] = space[8];
space['5xl'] = space[9];
space['6xl'] = space[10];
space['7xl'] = space[11];
space['8xl'] = space[12];

const fontSizes = [
	'14px', // xxs
	'18px', // xs
	'20px', // sm
	'24px', // md
	'30px', // lg
	'32px', // xl
	'48px', // xxl
	'60px', // xxxl
	'64px', // h1Mobile
	'130px', // h1Desktop
];

fontSizes.desktop = {
	xxs: fontSizes[0],
	xs: fontSizes[1],
	sm: fontSizes[2],
	md: fontSizes[3],
	lg: fontSizes[4],
	xl: fontSizes[5],
	xxl: fontSizes[6],
	xxxl: fontSizes[7],
	h1: fontSizes[9],
};

fontSizes.mobile = {
	xxs: fontSizes[0],
	xs: fontSizes[1],
	sm: fontSizes[2],
	md: fontSizes[3],
	lg: fontSizes[4],
	xl: fontSizes[5],
	xxl: fontSizes[6],
	xxxl: fontSizes[7],
	h1: fontSizes[8],
};

const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900];

fontWeights.normal = fontWeights[3];
fontWeights.bold = fontWeights[6];

export { breakpoints, space, fontWeights, fontSizes };
