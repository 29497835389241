/**
 * Returns the defined site map for the given page
 *
 * NOTE: Proper values will be provided when corresponding sections will be implemented
 *
 * @param {string} page - The page for which to retrieve the site map; Default is for the home page.
 * @param {boolean} excludeHomePage - If true the siteMap will return list without the home page element.
 * @returns {Array}
 */
export const siteMap = (contents, page = '/', excludeHomePage = false) => {
	const [HOME, SECTORS, OFFER, TECHNOLOGIES, OUR_TEAM, CASE_STUDIES] =
		contents;

	const items = {
		home: [
			{
				to: '/#home',
				text: HOME,
			},
			{
				to: '/#sectors',
				text: SECTORS,
			},
			{
				to: '/#blogs',
				text: OFFER,
			},
			{
				to: '/#technologies',
				text: TECHNOLOGIES,
			},
			{
				to: '/#our-team',
				text: OUR_TEAM,
			},
			{
				to: '/case-studies',
				text: CASE_STUDIES,
			},
		],
		/* TODO: Please provide a proper values when implementing the 'career' page */
		career: [
			{
				to: '/',
				text: 'example_1',
			},
		],
	};

	let pageItems = items[page === '/career' ? 'career' : 'home'];

	if (excludeHomePage && page !== '/career') {
		pageItems = pageItems.slice(1);
	}

	return pageItems;
};
