import { css } from '@styled-system/css';
import { createGlobalStyle } from 'styled-components';

const fontFace = (name, variant = 'normal', weight = 'normal') => {
	const formattedVariant = variant[0].toUpperCase() + variant.slice(1);
	return `
        @font-face {
            font-family: '${name}';
            src: url('/fonts/${name}/${name}-${formattedVariant}.woff2') format('woff2'),
                url('/fonts/${name}/${name}-${formattedVariant}.woff') format('woff'),
                url('/fonts/${name}/${name}-${formattedVariant}.ttf') format('truetype');
            font-weight: ${weight};
            font-display: fallback;
            font-style: ${variant};
        }`;
};

export const GlobalStyle = createGlobalStyle(
	css({
		'*': {
			fontFamily: `"Satoshi", sans-serif`,
			boxSizing: 'border-box',
		},
		body: {
			margin: 0,
			padding: 0,
		},
		'#gatsby-focus-wrapper': {
			bg: 'document_bg',
		},
		'.ant-form-item-explain-error': {
			letterSpacing: '0.1em',
		},
	}),
	fontFace('Satoshi', 'bold', '700'),
	fontFace('Satoshi', 'regular', '400'),
	fontFace('Stardom', 'regular', '400')
);
