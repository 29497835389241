const black = {
	'black-1': '#858585',
	'black-2': '#767676',
	'black-3': '#686868',
	'black-4': '#595959',
	'black-5': '#3B3B3B',
	'black-6': '#1E1E1E',
	'black-7': '#000000',
};

const white = {
	'white-1': '#FFFFFF',
	'white-2': '#E1E1E1',
	'white-3': '#C4C4C4',
	'white-4': '#A6A6A6',
	'white-5': '#979797',
	'white-6': '#898989',
	'white-7': '#6B6B6B',
};

const blue = {
	'blue-1': '#4B99E2',
	'blue-2': '#1E80DB',
	'blue-3': '#1866AF',
	'blue-4': '#124D83',
	'blue-5': '#0C3358',
	'blue-6': '#092642',
	'blue-7': '#061A2C',
};

const blueMatte = {
	'blue-matte-1': '#EFF7F9',
	'blue-matte-2': '#D0E2F2',
	'blue-matte-3': '#C0D8EE',
	'blue-matte-4': '#B0CEEA',
	'blue-matte-5': '#92B0CD',
	'blue-matte-6': '#7393B0',
	'blue-matte-7': '#466685',
	'blue-matte-8': '#365877',
	'blue-matte-9': '#274968',
	'blue-matte-10': '#183A5A',
};

const danger = '#E43030';

export const palette = {
	...black,
	...white,
	...blue,
	...blueMatte,
	danger,
};
