import PropTypes from 'prop-types';
import { Link } from 'components/common/Link';
import { ButtonBase, ButtonBorderWrapper } from './styles';

export const ContactButton = ({ to, onClick, text }) => (
	<Link to={to} withUnderline={false}>
		<ButtonBorderWrapper>
			<div className='button-border__overlay' />
			<div className='button-bg__overlay' />
			<div className='button-animation__overlay' />
			<ButtonBase onClick={onClick}>{text}</ButtonBase>
		</ButtonBorderWrapper>
	</Link>
);

ContactButton.propTypes = {
	to: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func,
};

ContactButton.defaultProps = {
	to: '',
	text: '',
	onClick: () => {},
};
