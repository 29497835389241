import PropTypes from 'prop-types';
import { Heading } from 'components/common/Typography';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const MenuItem = ({ to, text, onClick }) => {
	return (
		<AnchorLink to={to} onAnchorLinkClick={onClick}>
			<Heading level={2}>{text}</Heading>
		</AnchorLink>
	);
};

MenuItem.propTypes = {
	onClick: PropTypes.func,
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
	onClick: () => {},
};
