import PropTypes from 'prop-types';
import { siteMap } from 'utils/siteMap';
import { Link } from 'components/common/Link';
import { Paragraph } from 'components/common/Typography';
import { ItemWrapper, InnerWrapper } from './styles';

export const SiteMap = ({ page, menu, sitemap }) => {
	return (
		<ItemWrapper>
			<Paragraph variant='body_sm' color='inherit' fontWeight='bold'>
				{menu}
			</Paragraph>
			<InnerWrapper>
				{siteMap(sitemap, page).map(({ to, text }) => (
					<Link
						to={to}
						key={text}
						inline={false}
						textProps={{ variant: 'body_sm', color: 'inherit' }}
					>
						{text}
					</Link>
				))}
			</InnerWrapper>
		</ItemWrapper>
	);
};

SiteMap.propTypes = {
	menu: PropTypes.string,
	page: PropTypes.string.isRequired,
	sitemap: PropTypes.oneOfType([PropTypes.array]),
};

SiteMap.defaultProps = {
	menu: '',
	sitemap: '',
};
