import styled from 'styled-components';
import { css } from '@styled-system/css';
import FeatherIcon from 'feather-icons-react';

export const StyledIconButtonWrapper = styled.button(({ color }) =>
	css({
		margin: 0,
		padding: 0,
		border: 'none',
		display: 'flex',
		cursor: 'pointer',
		bg: 'transparent',
		alignItems: 'center',
		justifyContent: 'center',
		svg: {
			color,
		},
	})
);

export const StyledFeatherIcon = styled(FeatherIcon)(({ color }) =>
	css({
		color,
		svg: {
			color,
		},
	})
);

export const StyledIcon = styled.div(({ color, size }) =>
	css({
		svg: {
			color,
			width: size,
			height: size,
		},
	})
);
