import PropTypes from 'prop-types';
import { ChildrenType } from 'components/common/Types';
import { CookieBanner } from 'components/common/CookieBanner';

import { BlankLayout } from './BlankLayout';
import { DefaultLayout } from './DefaultLayout';

const Layout = ({ location, children, pageContext }) => {
	const {
		page: {
			GENERAL: {
				contents: {
					GENERAL_AGREEMENT_TEXT,
					GENERAL_AGREE_BUTTON_LABEL,
				},
			},
		},
	} = pageContext;

	if (pageContext.layout === 'Blank') {
		return (
			<BlankLayout pageContext={pageContext}>
				{children}
				<CookieBanner
					agreementText={GENERAL_AGREEMENT_TEXT}
					agreeButtonText={GENERAL_AGREE_BUTTON_LABEL}
				/>
			</BlankLayout>
		);
	}

	return (
		<DefaultLayout location={location} pageContext={pageContext}>
			{children}
			<CookieBanner
				agreementText={GENERAL_AGREEMENT_TEXT}
				agreeButtonText={GENERAL_AGREE_BUTTON_LABEL}
			/>
		</DefaultLayout>
	);
};

Layout.propTypes = {
	children: ChildrenType,
	location: PropTypes.oneOfType([PropTypes.object]),
	pageContext: PropTypes.oneOfType([PropTypes.object]),
};

Layout.defaultProps = {
	location: {},
	children: [],
	pageContext: {},
};

export default Layout;
