import { theme } from 'theme/theme';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { FlexBox } from 'components/common/FlexBox';
import { Paragraph } from 'components/common/Typography';

export const FooterContainer = styled.footer(
	css({
		zIndex: 1000,
		width: '100%',
		display: 'flex',
		minHeight: '362px',
		position: 'relative',
		background: 'linear-gradient(180deg, #195790 0%, #0E3960 100%)',
	})
);

export const InnerWrapper = styled(FlexBox)(
	css({
		pt: 'xxl',
		width: '100%',
		margin: '0 auto',
		maxWidth: '1440px',
		color: 'blue_matte',
		flexDirection: 'column',
		pb: ['xl', 'xl', 'xl', 'sm'],
		px: ['md', 'md', 'md', 'md', `${theme.space[12]}`],
	})
);

export const ItemsWrapper = styled(FlexBox)(
	css({
		mt: 'lg',
		rowGap: 'xl',
		width: '100%',
		flexWrap: 'wrap',
		color: 'inherit',
		flexDirection: ['column', 'row'],
	})
);

export const Copyright = styled(Paragraph)(
	css({
		mt: [
			`${theme.space[8]}`,
			`${theme.space[8]}`,
			`${theme.space[8]}`,
			'lg',
		],
		width: '100%',
		color: 'inherit',
		alignItems: 'center',
		justifyContent: 'center',
	})
);
