/**
 * Primary
 */

const primaryBase = {
	color: 'blue_matte',
	svg: { color: 'blue_matte' },
	backgroundImage:
		'linear-gradient(243.95deg, #2665E0 17.34%, rgba(86, 153, 207, 0.6) 94.88%)',
	boxShadow:
		'30px 40px 20px rgba(38, 105, 226, 0.01), 17px 23px 17px rgba(38, 105, 226, 0.05), 8px 10px 13px rgba(38, 105, 226, 0.09), 2px 3px 7px rgba(38, 105, 226, 0.1), inset 0px 4px 8px rgba(30, 65, 134, 0.25)',
};

export const primary = {
	// This background sets the border-like gradient on ButtonWrapper layer
	background:
		'linear-gradient(303.43deg, #2931DB 1.04%, rgba(100, 162, 235, 0) 70.61%)',

	'.ant-btn-default:not(:disabled):not(.ant-btn-disabled)': {
		...primaryBase,
	},

	'.button-bg-overlay': {
		backgroundImage:
			'linear-gradient(247.54deg, #285CC2 18.51%, rgba(26, 54, 77, 0.6) 138.28%)',
	},

	'[disabled], [disabled]:focus, [disabled]:active, [disabled]:hover': {
		...primaryBase,
	},
};

/**
 * Secondary
 */
const secondaryBase = {
	color: 'blue_300',
	svg: { color: 'blue_300' },
	backgroundImage:
		'linear-gradient(243.95deg, #C9D0DF 17.34%, rgba(215, 233, 246, 0.6) 94.88%)',
	boxShadow:
		'30px 40px 20px rgba(38, 105, 226, 0.01), 17px 23px 17px rgba(38, 105, 226, 0.05), 8px 10px 13px rgba(38, 105, 226, 0.09), 2px 3px 7px rgba(38, 105, 226, 0.1), inset 0px 4px 8px rgba(30, 65, 134, 0.25)',
};

export const secondary = {
	// This background sets the border-like gradient on ButtonWrapper layer
	background:
		'linear-gradient(286.2deg, #F5F5F5 -7%, rgba(255, 255, 255, 0) 70.69%)',

	'.ant-btn-default:not(:disabled):not(.ant-btn-disabled)': {
		...secondaryBase,
	},

	'.button-bg-overlay': {
		backgroundImage:
			'linear-gradient(243.95deg, #7D98CC 17.34%, rgba(212, 230, 244, 0.6) 94.88%)',
	},

	'[disabled], [disabled]:focus, [disabled]:active, [disabled]:hover': {
		...secondaryBase,
	},
};

/**
 * Transparent
 */
const transparentBase = {
	color: 'primary',
	border: '1px solid',
	borderColor: 'primary',
	svg: { color: 'primary' },
	background: 'transparent',
	transition: 'opacity 0.3s',
};

export const transparent = {
	'.ant-btn-default:not(:disabled):not(.ant-btn-disabled)': {
		...transparentBase,

		'&:hover': {
			color: 'white',
			svg: { color: 'white' },
		},

		'&:focus, &:active': {
			color: 'white',
			svg: { color: 'white' },
			backgroundImage:
				'linear-gradient(90deg, #2C7EC9 13.95%, #0E4373 50%)',
		},
	},

	'.button-bg-overlay': {
		'&:hover': {
			backgroundImage:
				'linear-gradient(90deg, #2C7EC9 13.95%, #0E4373 50%)',
		},
	},

	'[disabled], [disabled]:focus, [disabled]:active, [disabled]:hover': {
		...transparentBase,
		opacity: 0.9,
	},
};

/**
 * Loading
 */

export const loading = {
	'.ant-btn-default': {
		...transparentBase,
		color: 'white',
		svg: { color: 'white' },
		borderColor: 'transparent',
	},

	'[disabled], [disabled]:focus, [disabled]:active, [disabled]:hover': {
		...transparentBase,
		opacity: 1,
		color: 'white',
		svg: { color: 'white' },
		borderColor: 'transparent',
	},
};
