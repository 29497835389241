import PropTypes from 'prop-types';
import { LinkedInSvg } from 'assets/icons';
import { Icon } from 'components/common/Icon';
import { Anchor } from 'components/common/Anchor';
import { Paragraph } from 'components/common/Typography';
import { InnerWrapper, ItemWrapper } from './styles';

export const FindUs = ({ title, linkedinLink, linkedinAria }) => (
	<ItemWrapper>
		<Paragraph variant='body_sm' color='inherit' fontWeight='bold'>
			{title}
		</Paragraph>
		<InnerWrapper>
			<Anchor openInNewTab href={linkedinLink} ariaLabel={linkedinAria}>
				<Icon size={24} color='inherit' icon={<LinkedInSvg />} />
			</Anchor>
		</InnerWrapper>
	</ItemWrapper>
);

FindUs.propTypes = {
	title: PropTypes.string,
	linkedinLink: PropTypes.string,
	linkedinAria: PropTypes.string,
};

FindUs.defaultProps = {
	title: '',
	linkedinLink: '',
	linkedinAria: '',
};
