import { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'theme/index';
import { Header } from 'components/domain/App/Header';
import { Footer } from 'components/domain/App/Footer';
import { ChildrenType } from 'components/common/Types';
import { PageContainer } from 'components/common/Containers';
import { MobileMenu } from 'components/domain/App/MobileMenu';

export const DefaultLayout = ({ location, pageContext, children }) => {
	const { pathname } = location;
	const blueThemePaths = ['/', '/blog/*'];
	const isBlueTheme = !!blueThemePaths.filter((path) =>
		path.includes('*')
			? pathname.includes(path.replace('*', ''))
			: path === pathname
	).length;

	const theme = isBlueTheme ? 'blue' : 'white';
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	return (
		<ThemeProvider>
			<PageContainer>
				<MobileMenu
					page={pathname}
					open={mobileMenuOpen}
					pageContext={pageContext}
					onClose={setMobileMenuOpen}
				/>
				<Header
					theme={theme}
					page={pathname}
					pageContext={pageContext}
					openMobileMenuHandler={setMobileMenuOpen}
				/>
				{children}
				<Footer page={pathname} pageContext={pageContext} />
			</PageContainer>
		</ThemeProvider>
	);
};

DefaultLayout.propTypes = {
	children: ChildrenType,
	location: PropTypes.oneOfType([PropTypes.object]),
	pageContext: PropTypes.oneOfType([PropTypes.object]),
};

DefaultLayout.defaultProps = {
	children: [],
	location: {},
	pageContext: {},
};
